import * as React from "react";
import { inject, observer } from "mobx-react";
import { UitkLink } from "@egds/react-core/link";
import { AnalyticsStore } from "bernie-plugin-mobx";
import { TopNeighborhoodsCarouselProps, DestinationNeighborhoodsViewProps } from "../typings";
import { Neighborhood, TeNeighborhoodFlexModuleResult } from "typings/microserviceModels/te-neighborhood-flex-module";
import { ItemKeyHelper } from "components/utility/ItemKeyHelper";
import {
  NeighborhoodCard,
  NeighborhoodCardFooter,
} from "src/components/flexComponents/DestinationNeighborhoods/components/NeighborhoodCard";
import { TrackedLink } from "src/components/utility/analytics/TrackedLink";
import { IsomorphicCarousel } from "src/components/shared/IsomorphicCarousel/IsomorphicCarousel";
import { useLocalization } from "@shared-ui/localization-context";
import { UitkHeading, UitkText } from "@egds/react-core/text";
import { HotelSearchLinkBuilder } from "components/utility/HotelSearchLinkBuilder/HotelSearchLinkBuilder";
import { withStores } from "stores";
import { LazyLoad } from "src/components/utility/LazyLoad/LazyLoad";
import { getFmId } from "src/stores/ExperienceTemplateStore";
import { UitkSpacing } from "@egds/react-core/spacing";
import { IntersectionTracker } from "@shared-ui/clickstream-analytics-context";

export const TopNeighborhoodsCarousel = withStores(
  "context",
  "analytics",
  "flexModuleModelStore"
)(
  observer((props: TopNeighborhoodsCarouselProps) => {
    const { analytics, context, templateComponent, flexModuleModelStore, isClickable = true } = props;

    if (!templateComponent) {
      return null;
    }

    return (
      <DestinationNeighborhoodsView
        analytics={analytics}
        templateComponent={templateComponent}
        flexModuleModelStore={flexModuleModelStore}
        context={context}
        jumplinkRef={React.createRef()}
        isClickable={isClickable}
      />
    );
  })
);

const DestinationNeighborhoodsView = inject("flexModuleModelStore")(
  observer((props: DestinationNeighborhoodsViewProps & { analytics?: AnalyticsStore }) => {
    const { templateComponent, flexModuleModelStore, context, isClickable } = props;

    const {
      metadata: { id },
      config: { fmTitleId },
    } = templateComponent;
    const fmId = getFmId(templateComponent);
    const { hideTitle } = templateComponent.config;
    const model = flexModuleModelStore.getModel(id) as TeNeighborhoodFlexModuleResult;

    if (!model) {
      return null;
    }

    const neighborhoods: Neighborhood[] = model.content?.items;
    const { cardBorderDisabled, mediaAspectRatio } = model;

    //When there are less than three neighborhoods the carousel has not to be displayed
    const hasEnoughItems = neighborhoods?.length >= 3;

    if (!hasEnoughItems) {
      return null;
    }

    const carouselProps = {
      itemsVisible: { lg: 3, md: 2, sm: 1 },
      pageBy: 1,
      peek: true,
      carouselName: "TopCarousel",
      itemsMaxHeight: true,
    };

    const keyHelper = new ItemKeyHelper(id);
    const neighborhoodsKeyHelper: ItemKeyHelper = new ItemKeyHelper("popular-neighborhood");

    const { title, subTitle, learnMore } = model.content;
    const { formatText } = useLocalization();
    return (
      <LazyLoad context={context}>
        <IntersectionTracker linkName="findHotelsLink" referrerId="TopNeighborhoods.findHotelsLink.carousel.Impression">
          <UitkSpacing padding={{ block: "four" }}>
            <div
              className="DestinationNeighborhoods topNeighborhoods"
              id={id}
              data-fm={fmId}
              data-fm-title-id={fmTitleId}
            >
              {!hideTitle && (
                <UitkHeading tag="h2" size={4}>
                  {title}
                </UitkHeading>
              )}
              {subTitle && (
                <UitkText size={300}>
                  {subTitle}
                  {Boolean(learnMore?.url && learnMore?.text) && (
                    <>
                      {" "}
                      <UitkLink inline className="preWrap">
                        <TrackedLink
                          moduleName="TopNeighborhoods"
                          href={new HotelSearchLinkBuilder(learnMore.url).build(props.context)}
                          linkName="learnMoreAbout"
                          rfrr="learnMoreAboutRegion"
                        >
                          {learnMore.text}
                        </TrackedLink>
                      </UitkLink>
                    </>
                  )}
                </UitkText>
              )}
              <UitkSpacing margin={{ blockstart: "three" }}>
                <IsomorphicCarousel
                  buttonText={{
                    nextButton: formatText("carousel.item.next"),
                    prevButton: formatText("carousel.item.prev"),
                  }}
                  {...carouselProps}
                >
                  {neighborhoods.map((neighborhood: Neighborhood, neighborhoodIndex: number) => {
                    const cardProps = {
                      withBorder: Boolean(!cardBorderDisabled),
                      key: neighborhoodsKeyHelper.next(),
                      neighborhood,
                      position: neighborhoodIndex + 1,
                      context,
                      isDesktop: true,
                      isClickable,
                      mediaAspectRatio,
                    };

                    return (
                      <IntersectionTracker
                        linkName="findHotelsLink"
                        referrerId={
                          neighborhood.region.isGPT
                            ? `TopNeighborhoods.findHotelsLink.${neighborhood.id}.GPT.${cardProps.position}.Impression`
                            : `TopNeighborhoods.findHotelsLink.${neighborhood.id}.Content.${cardProps.position}.Impression`
                        }
                        key={keyHelper.next()}
                      >
                        <NeighborhoodCard {...cardProps}>
                          <NeighborhoodCardFooter>
                            <UitkLink className="uitk-card-separator-top">
                              <TrackedLink
                                moduleName="TopNeighborhoods"
                                href={new HotelSearchLinkBuilder(neighborhood.callToActionLink).build(props.context)}
                                linkName="findHotelsIn"
                                rfrr={
                                  neighborhood.region.isGPT
                                    ? `findHotelsLink.${neighborhood.id}.GPT.${neighborhoodIndex + 1}`
                                    : `findHotelsLink.${neighborhood.id}.Content.${neighborhoodIndex + 1}`
                                }
                              >
                                {formatText(neighborhood.callToActionLinkLocKey, neighborhood.region.name)}
                              </TrackedLink>
                            </UitkLink>
                          </NeighborhoodCardFooter>
                        </NeighborhoodCard>
                      </IntersectionTracker>
                    );
                  })}
                </IsomorphicCarousel>
              </UitkSpacing>
            </div>
          </UitkSpacing>
        </IntersectionTracker>
      </LazyLoad>
    );
  })
);

export default TopNeighborhoodsCarousel;
