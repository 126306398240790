import * as React from "react";
import { UitkCard, UitkCardLink } from "@egds/react-core/cards";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { UitkFigureAspectRatioType } from "@egds/react-core/images";
import { useLocalization } from "@shared-ui/localization-context";
import { HotelSearchLinkBuilder } from "components/utility/HotelSearchLinkBuilder/HotelSearchLinkBuilder";
import { DesktopCardContent, MobileCardContent } from "./NeighborhoodCardContent";
import { NeighborhoodCardProps } from "../typings";
import { renderScrimAttributionTextHTML } from "../utils/renderScrimAttributionTextHTML";
import { NeighborhoodCardMedia } from "./NeighborhoodCardMedia";
import { UitkSpacing } from "@egds/react-core/spacing";

const localizeKnownForLabel = (affinities: string) => {
  const { formatText } = useLocalization();

  return formatText("destinationNeighborhood.topNeighborhoods.knownFor", affinities);
};

const getSpecialComponent = (children: React.ReactNode) => {
  const components: { [key: string]: React.ComponentElement<any, any> | null } = {};

  React.Children.map(children, (child: React.ComponentElement<any, any>) => {
    switch (child.type.displayName) {
      case "NeighborhoodCardFooter":
        components[child.type.displayName] = child;
    }
  });

  return components;
};

export const NeighborhoodCard: React.FC<NeighborhoodCardProps> = (props) => {
  const {
    className = "",
    context,
    neighborhood,
    isDesktop,
    isClickable,
    children,
    view,
    mediaAspectRatio = UitkFigureAspectRatioType.R21_9,
    position,
  } = props;
  const { id, link, media, region, affinities } = neighborhood;

  const name = region?.name || "";
  const description = region?.description || "";

  /** Concatenate affinities into a single string (e.g. beaches, surfing, seafood)  */
  const neighborhoodAffinities = affinities.reduce((result, affinity) => {
    if (result === "") {
      return affinity.name;
    }

    return `${result}, ${affinity.name}`;
  }, "");

  const averageRating = neighborhood.aggregateReviewsAndRatings?.averageRating;
  const totalRatings = neighborhood.aggregateReviewsAndRatings?.totalRatings;
  const { NeighborhoodCardFooter: CardFooter } = getSpecialComponent(children);
  const defaultSubTitle = neighborhoodAffinities && localizeKnownForLabel(neighborhoodAffinities);
  const containerClasses = `destinationNeighborhoodsCard fullHeight ${className}`;

  return isDesktop ? (
    <div className={containerClasses}>
      <UitkLayoutFlex direction="column" blockSize="full_size">
        <UitkLayoutFlexItem>
          <UitkCard className="fullHeight" border>
            <UitkLayoutFlex direction="column" blockSize="full_size">
              <UitkLayoutFlexItem>
                <div>
                  <NeighborhoodCardMedia
                    id={id}
                    regionName={name}
                    media={media}
                    ratio={mediaAspectRatio}
                    renderAttributionText={renderScrimAttributionTextHTML}
                    attributionType={view}
                  />
                </div>
              </UitkLayoutFlexItem>
              <UitkLayoutFlexItem>
                <div>
                  <DesktopCardContent
                    title={name}
                    subTitle={neighborhood.infoPois}
                    description={description}
                    averageRating={averageRating}
                    totalRatings={totalRatings}
                    context={context}
                  />
                </div>
              </UitkLayoutFlexItem>
              <UitkLayoutFlexItem alignSelf="end">{CardFooter}</UitkLayoutFlexItem>
              {isClickable && link && (
                <UitkCardLink>
                  <TrackedLink
                    moduleName="DestinationNeighborhoods"
                    href={new HotelSearchLinkBuilder(link).build(context)}
                    rfrr={
                      region.isGPT
                        ? `TG.LP.Dest.Neighborhoods.${neighborhood.id}.GPT.${position}`
                        : `TG.LP.Dest.Neighborhoods.${neighborhood.id}.Content.${position}`
                    }
                    className="uitk-card-link"
                  >
                    {name}
                  </TrackedLink>
                </UitkCardLink>
              )}
            </UitkLayoutFlex>
          </UitkCard>
        </UitkLayoutFlexItem>
      </UitkLayoutFlex>
    </div>
  ) : (
    <div className="destinationNeighborhoodsCard">
      <UitkCard>
        <UitkCardLink>
          <TrackedLink
            moduleName="DestinationNeighborhoods"
            href={new HotelSearchLinkBuilder(link).build(context)}
            rfrr={
              region.isGPT
                ? `TG.LP.Dest.Neighborhoods.${neighborhood.id}.GPT.${position}`
                : `TG.LP.Dest.Neighborhoods.${neighborhood.id}.Content.${position}`
            }
            className="uitk-card-link"
          >
            {name}
          </TrackedLink>
        </UitkCardLink>

        <UitkLayoutFlex>
          <UitkLayoutFlexItem minWidth="third_width" shrink={0}>
            <div>
              <NeighborhoodCardMedia id={id} regionName={name} media={media} />
            </div>
          </UitkLayoutFlexItem>

          <UitkLayoutFlexItem grow={1}>
            <div>
              <MobileCardContent title={name} subTitle={defaultSubTitle} />
            </div>
          </UitkLayoutFlexItem>
        </UitkLayoutFlex>
      </UitkCard>
    </div>
  );
};

export const NeighborhoodCardFooter: React.FC = ({ children }) => (
  <UitkSpacing padding="three">
    <div className="uitk-card-content destinationNeighborhoodsCardFooter">{children}</div>
  </UitkSpacing>
);
NeighborhoodCardFooter.displayName = "NeighborhoodCardFooter";

export default NeighborhoodCard;
