import * as React from "react";

import { UitkFigure, UitkFigureAspectRatioType, UitkImage } from "@egds/react-core/images";

import { MediaAttribution } from "components/utility/MediaAttributionUtil";

import { NeighborhoodCardMediaProps } from "../typings";

import { cropResizeSrc } from "components/shared/BlossomImage/BlossomImage";

export const NeighborhoodCardMedia = (props: NeighborhoodCardMediaProps) => {
  const { regionName, media, attributionType, renderAttributionText, ratio } = props;

  const { mediaUrl } = media;

  const neighborhoodImageUrl = mediaUrl && cropResizeSrc(mediaUrl, { width: 350, height: 192 }, "medium");
  const attribution =
    attributionType === "destination"
      ? MediaAttribution(media, regionName)
      : MediaAttribution(media, regionName, renderAttributionText);

  return (
    <UitkFigure ratio={ratio || UitkFigureAspectRatioType.R16_9} imageFit="cover">
      <UitkImage src={neighborhoodImageUrl} alt={attribution.attributionAltText} placeholderImage lazyLoading="lazy" />

      {attribution.attributionText}
    </UitkFigure>
  );
};
