import * as React from "react";
import { UitkCardContentSection } from "@egds/react-core/cards";
import { AggregateRating } from "components/shared/Rating/AggregateRating";
import { UitkHeading, UitkText } from "@egds/react-core/text";
import { NeighborhoodDescriptionProps, NeighborhoodCardContentProps } from "../typings";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { UitkSpacing } from "@egds/react-core/spacing";

export const NeighborhoodCardHeader = (props: NeighborhoodCardContentProps) => {
  const { title, subTitle, averageRating, totalRatings, context } = props;

  return (
    <>
      <section>
        {Boolean(title) && (
          <UitkHeading tag="h3" size={5}>
            {title}
          </UitkHeading>
        )}
        {Boolean(subTitle) && <UitkText size={200}>{subTitle}</UitkText>}
      </section>
      {Boolean(averageRating) && (
        <UitkSpacing margin={{ blockstart: "four" }}>
          <section>
            <AggregateRating
              rating={averageRating}
              reviewCount={totalRatings}
              context={context}
              localizationKey="destinationNeighborhood"
              withSuperlative
            />
          </section>
        </UitkSpacing>
      )}
    </>
  );
};

export const NeighborhoodDescription = (props: NeighborhoodDescriptionProps) => {
  return (
    <UitkText size={300}>
      <div dangerouslySetInnerHTML={{ __html: `<p> ${props.description} </p>` }} />
    </UitkText>
  );
};

export const DesktopCardContent = (props: NeighborhoodCardContentProps) => {
  const { title, subTitle, averageRating, totalRatings, context, description } = props;

  return (
    <div className="uitk-card-content">
      <UitkSpacing padding={{ blockstart: "three", inline: "three" }}>
        <section>
          <NeighborhoodCardHeader
            title={title}
            subTitle={subTitle}
            averageRating={averageRating}
            totalRatings={totalRatings}
            context={context}
          />
        </section>
      </UitkSpacing>
      <UitkSpacing padding={{ block: "two", inline: "three" }}>
        <section>
          <NeighborhoodDescription description={description} />
        </section>
      </UitkSpacing>
    </div>
  );
};

export const MobileCardContent = (props: NeighborhoodCardContentProps & { subTitle?: React.ReactNode }) => {
  return (
    <UitkCardContentSection>
      <UitkLayoutFlex>
        <UitkLayoutFlexItem>
          <UitkText>
            <NeighborhoodCardHeader title={props.title} subTitle={props.subTitle} />
          </UitkText>
        </UitkLayoutFlexItem>
      </UitkLayoutFlex>
    </UitkCardContentSection>
  );
};
