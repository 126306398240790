import * as React from "react";
import { RenderAttributionTextHTML } from "components/utility/MediaAttributionUtil";
import { UitkScrim } from "@egds/react-core/scrim";
import { TrackedLink } from "src/components/utility/analytics/TrackedLink";
import { useLocalization } from "@shared-ui/localization-context";

export const renderScrimAttributionTextHTML: RenderAttributionTextHTML = ({ mainText, altText, attribUrl }) => {
  const { formatText } = useLocalization();
  const attributionLinkText = formatText("hero.attribution.openLink", altText);

  return (
    <UitkScrim className="AttributionTextScrim" title={altText}>
      <TrackedLink
        title={attributionLinkText}
        className="uitk-attribution-link"
        moduleName="attributionsText.ownerLink"
        href={attribUrl}
        rffr="Attributions.click"
      >
        <span dangerouslySetInnerHTML={{ __html: mainText }} />
      </TrackedLink>
    </UitkScrim>
  );
};
